import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { history } from 'store'

import { SigninPath, SignoutPath } from 'identity/userManager'
import { useEffect, lazy, Suspense } from 'react'
import { decorateUrl } from 'components/common/UTMscript'
import PopUp from 'components/common/PopUp'
import TopBar from 'components/common/TopBar'
import Webinar from 'pages/Webinar'
import WebinarCatalog from 'pages/WebinarCatalog'
import SiteControlScript from 'components/common/SiteControlScript'
import OnBoardingNext from 'pages/OnBoardingNext'
import Certificate from 'pages/Certificate'

const Home = lazy(() => import('pages/Home'))
const PrivateHome = lazy(() => import('components/Home/Private'))
const Signin = lazy(() => import('pages/Signin'))
const Error404 = lazy(() => import('pages/404'))
const Catalog = lazy(() => import('pages/Catalog'))
const CatalogLearningPaths = lazy(() => import('pages/CatalogLearningPaths'))
const Pricing = lazy(() => import('pages/Pricing'))
const Contact = lazy(() => import('pages/Contact'))
const ContactSuccess = lazy(() => import('pages/ContactSuccess'))
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'))
const LegalNotice = lazy(() => import('pages/LegalNotice'))
const TermsOfServices = lazy(() => import('pages/TermsOfServices'))
const Trainer = lazy(() => import('pages/Trainer'))
const Profile = lazy(() => import('pages/Profile'))
const PrivateRoute = lazy(() => import('./PrivateRoute'))
const Search = lazy(() => import('pages/Search'))
const Maintenance = lazy(() => import('pages/Maintenance'))
const AddItemToCart = lazy(() => import('pages/AddItemToCart'))
const Cart = lazy(() => import('pages/Cart'))
const CheckoutSubscription = lazy(() => import('pages/CheckoutSubscription'))
const CoursePricingResponsive = lazy(() => import('pages/CoursePricingResponsive'))
const PreSignOut = lazy(() => import('pages/PreSignOut'))
const OfflinePlayer = lazy(() => import('pages/OfflinePlayer'))
const Signout = lazy(() => import('pages/Signout'))
const PreSignup = lazy(() => import('pages/PreSignup'))
const UTMscript = lazy(() => import('components/common/UTMscript'))
const Course = lazy(() => import('pages/Course'))
const LearningPathPricingResponsive = lazy(() => import('pages/LearningPathPricingResponsive'))
const LearningPathRoot = lazy(() => import('pages/LearningPath'))

export default () => {
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
    history.listen(() => { window.scrollTo(0, 0) })
  }, [])

  return (
    <Suspense fallback={<div />}>
      <ConnectedRouter history={history}>
        <UTMscript />
        <PopUp />
        <TopBar />
        <SiteControlScript />
        <Switch>
          <Route exact path={HomePath}>
            <Home />
          </Route>
          <Route exact path={SigninPath}>
            <Signin />
          </Route>
          <Route exact path={PreSignupPath}>
            <PreSignup />
          </Route>
          <Route exact path={PreSignoutPath}>
            <PreSignOut />
          </Route>
          <Route exact path={SignoutPath}>
            <Signout />
          </Route>
          <Route exact path={[CatalogPath, CategoryPath]}>
            <Catalog />
          </Route>
          <Route exact path={[CatalogLearningPathsPath, CategoryLearningPathsPath]}>
            <CatalogLearningPaths />
          </Route>
          <Route exact path={PricingPath}>
            <Pricing />
          </Route>
          <Route exact path={ContactPath}>
            <Contact />
          </Route>
          <Route exact path={ContactSuccessPath}>
            <ContactSuccess />
          </Route>
          <Route exact path={PrivacyPolicyPath}>
            <PrivacyPolicy />
          </Route>
          <Route exact path={LegalNoticePath}>
            <LegalNotice />
          </Route>
          <Route exact path={TermsOfServicesPath}>
            <TermsOfServices />
          </Route>
          <Route exact path={TrainerPath}>
            <Trainer />
          </Route>
          <Route exact path={SearchPath}>
            <Search />
          </Route>
          <Route exact path={`${TopicPath}/:term`}>
            <Search />
          </Route>
          <PrivateRoute path={ProfilePath}>
            <Profile />
          </PrivateRoute>
          <Redirect exact from={LearningPath} to={decorateUrl(historyPath)} />
          <PrivateRoute path={Dashboard}>
            <PrivateHome />
          </PrivateRoute>
          <PrivateRoute path={OnBoardingNeutralPath}>
            <OnBoardingNext />
          </PrivateRoute>
          <Route exact path={CoursePath}>
            <Course />
          </Route>
          <Route exact path={CourseVideoPath}>
            <Course />
          </Route>
          <Route exact path={MaintenancePath}>
            <Maintenance />
          </Route>
          <Route exact path={AddItemToCartPath}>
            <AddItemToCart />
          </Route>
          <Route path={CartPath}>
            <Cart />
          </Route>
          <Route exact path={CertificatePath}>
            <Certificate />
          </Route>
          <Route path={SubscriptionCheckoutPath}>
            <CheckoutSubscription />
          </Route>
          <Route exact path={MobileCoursePricingPath}>
            <CoursePricingResponsive />
          </Route>
          <Route exact path={OfflinePlayerPath}>
            <OfflinePlayer />
          </Route>
          <Route exact path={MobileLearningPathPricingPath}>
            <LearningPathPricingResponsive />
          </Route>
          <Route exact path={LearningPathPath}>
            <LearningPathRoot />
          </Route>
          <Route exact path={WebinarPath}>
            <Webinar />
          </Route>
          <Route exact path={WebinarsPath}>
            <WebinarCatalog />
          </Route>
          <Route exact path={WebinarsCategoryPath}>
            <WebinarCatalog />
          </Route>
          <Route path={pageNotFoundPath}>
            <Error404 />
          </Route>
          <Route>
            <Error404 />
          </Route>
        </Switch>
      </ConnectedRouter>
    </Suspense>
  )
}

export const HomePath = '/'
export const Dashboard = '/dashboard'
export const ErrorPath = '/error'
export const CatalogPath = '/formations'
export const CategoryPath = '/formations/:categoryName'
export const CategoryNeutralPath = '/formations'
export const CatalogLearningPathsPath = '/parcours-apprentissage'
export const CategoryLearningPathsPath = '/parcours-apprentissage/:categoryName'
export const CategoryNeutralLearningPathsPath = '/parcours-apprentissage'
export const PricingPath = '/tarifs'
export const ContactPath = '/support-contact'
export const ContactSuccessPath = '/contactSuccess'
export const PrivacyPolicyPath = '/confidentialite'
export const LegalNoticePath = '/mentions-legales'
export const TermsOfServicesPath = '/cgu'
export const TrainerPath = '/formateur/:slug'
export const TrainerPrimaryPath = '/formateur'
export const ProfilePath = '/profile'
export const PasswordPath = '/password'
export const NotificationsPath = '/notifications'
export const SubscriptionPath = '/subscription'
export const PaymentPath = '/payment'
export const SchedulePath = '/schedule'
export const SearchPath = '/Search'
export const TopicPath = '/topic'
export const LearningPath = '/Apprentissage'
export const historyPath = '/Historique'
export const favoritesPath = '/favoris'
export const certificatesPath = '/Attestations'
export const recommendedPath = '/Recommandees'
export const boughtPath = '/Achetees'
export const CertificateNeutralPath = '/Attestation'
export const CertificatePath = `${CertificateNeutralPath}/:id`
export const MaintenancePath = '/maintenance'
export const CourseNeutralPath = '/tutoriel'
export const CoursePath = '/tutoriel/:slug'
export const CourseVideoPath = '/tutoriel/:slug/:videoSlug'
export const AddItemToCartPath = '/nouvelle-achat'
export const CartPath = '/mon-panier'
export const CartPaymentMethodsPath = '/moyen-de-paiement'
export const CartPaymentSuccessPath = '/paiement-reussie'
export const OpcoPath = '/opco'
export const SubscriptionCheckoutPath = '/panier-Abonnement'
export const SubscriptionCartPaymentSuccessPath = '/paiement-reussie'
export const MobileCoursePricingPath = '/tarifs-mobile'
export const pageNotFoundPath = '/404'
export const PreSignoutPath = '/PreSignout'
export const OfflinePlayerPath = '/formez-vous-en-mobilite'
export const CourseQuizPath = '/quiz-demo'
export const PreSignupPath = '/inscription'
export const LearningPathRootPath = '/parcours'
export const LearningPathPath = '/parcours/:slug'
export const LearningPathNeutralPath = '/parcours'
export const MobileLearningPathPricingPath = '/parcours-tarifs-mobile'
export const WebinarsPath = '/webinaires'
export const WebinarsCategoryPath = `${WebinarsPath}/:categorySlug`
export const WebinarNeutralPath = '/webinaire'
export const WebinarPath = `${WebinarNeutralPath}/:slug`
export const OnBoardingNeutralPath = '/onboarding'
export const OnBoardingFirstPath = `${OnBoardingNeutralPath}/etape-1`
export const OnBoardingSecondPath = `${OnBoardingNeutralPath}/etape-2`
export const PhoneValidationPath = `${OnBoardingNeutralPath}/validation-telephone`
export const InvitedPasswordSetPath = `${OnBoardingNeutralPath}/set-password`
