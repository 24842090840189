import { all } from 'redux-saga/effects'
import { trainerRootSaga } from 'pages/Trainer/store'
import { userRootSaga } from 'components/Profile/store'
import { shopRootSaga } from 'components/common/Support/store'
import { catalogRootSaga } from 'components/Catalog/store'
import { searchRootSaga } from 'components/common/Searchbar/store'
import { LearningRootSaga } from 'components/Learning/store'
import { certificateRootSaga } from 'pages/Certificate/store'
import { CourseRootSaga } from 'pages/Course/store'
import { QuizRootSaga } from 'pages/Course/Quizz/store'
import { referentialRootSaga } from './referential'
import { paymentRootSaga } from './payment'
import { cartRootSaga } from 'components/Cart/store'
import { IdentityRootSaga } from 'identity/store'
import { subscriptionCartRootSaga } from 'components/CheckoutSubscription/store'
import { onboardingRootSaga } from 'pages/OnBoardingNext/store'
import { loadingAppRootSaga } from './config'
import { LearningPathRootSaga } from 'pages/LearningPath/store'
import { marketingRootSaga } from './marketing'
import { WebinarRootSaga } from 'pages/Webinar/store'
import { webinarsRootSaga } from 'pages/WebinarCatalog/store'
import { PublicHomeRootSaga } from 'components/Home/store'

export default function * () {
  yield all([
    trainerRootSaga(),
    userRootSaga(),
    shopRootSaga(),
    catalogRootSaga(),
    searchRootSaga(),
    LearningRootSaga(),
    certificateRootSaga(),
    CourseRootSaga(),
    referentialRootSaga(),
    cartRootSaga(),
    paymentRootSaga(),
    IdentityRootSaga(),
    subscriptionCartRootSaga(),
    onboardingRootSaga(),
    loadingAppRootSaga(),
    QuizRootSaga(),
    LearningPathRootSaga(),
    marketingRootSaga(),
    WebinarRootSaga(),
    webinarsRootSaga(),
    PublicHomeRootSaga()
  ])
}
